html,
body {
  margin: 0;
  height: 100%;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
}

#root {
  height: 100%;
}

.containerDiv {
  display: grid;
  min-height: 100%;
  grid-template-columns: auto;
  grid-template-rows: min-content auto min-content;
}

header,
footer {
  grid-column-start: 1;
  grid-column-end: 2;
  background-color: #434040;
  color: white;
  z-index: 0;
}

header {
  display: grid;
  grid-template-columns: 0.5fr 1.5fr;
  padding-left: 1.5em;
  position: -webkit-sticky;
  position: sticky;
  top: 70;
  /* z-index: 10; */
}

.t2-logo {
  height: 30px;
  padding: 10px 0px 5px 0px;
}

nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5em;
  padding-right: 1.5em;
}

nav a {
  color: white;
  text-decoration: none;
}

footer {
  display: flex;
  padding: 1.5em;
  margin-top: 1.5em;
}

.footer-left-div {
  flex: 50%;
  text-align: left;
}

.footer-right-div {
  flex: 50%;
  text-align: right;
}

main {
  padding: 0 2.5em 2.5em 2.5em;
}

.table-header-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.search-table-div {
  display: inline;
  text-align: right;
  padding-top: 1.5em;
}

button {
  background-color: #2b2577; /* Green */
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 3px 0 3px;
  /* font-size: 16px; */
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 5px;
}

table th {
  border: 1px solid #ddd;
  padding: 8px;
  /* position: sticky;
  top: 215px; */
}

table td {
  border: 1px solid #ddd;
  padding: 8px;
}

table thead {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #154836;
  color: white;
}

table .header-cell {
  background-color: azure;
  white-space: nowrap;
  padding-right: 2em;
}

table .link {
  color: blue;
  cursor: pointer;
}

table .link:hover {
  text-decoration: underline;
}

label {
  display: block;
  margin-right: 15px;
  margin-bottom: 3px;
}

.form-div {
  padding: 8px 0px;
}

input {
  padding: 5px 10px;
  /* width: 250px; */
}

textarea {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.95rem;
}

textarea::placeholder {
  font-family: Arial;
  color: #aaa;
}

.email {
  width: 15rem;
}

select {
  padding: 5px 10px;
}

.row {
  padding: 10px 25px;
  background-color: aliceblue;
}

.column {
  float: left;
  width: 33.33%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.button-div {
  padding: 10px;
  text-align: right;
  background-color: #ddd;
}

i {
  cursor: pointer;
}
