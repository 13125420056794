.schedule-form-country-selector {
  width: 180px;
  display: inline;
}
.schedule-form-centre-selector {
  min-width: 200px;
  margin-left: 5px;
  display: inline;
}
.schedule-form-timeslot-selector {
  width: 130px;
}
.schedule-form-classroom-selector {
  width: 145px;
}
.schedule-form-teacher-selector {
  width: 250px;
}
.schedule-form-content-type-selector {
  width: 150px;
}
.schedule-form-content-pathway-selector {
  width: 150px;
}
.schedule-form-content-theme-selector {
  width: 360px;
}
.schedule-form-lesson-selector {
  width: 360px;
}
.schedule-form-strand-selector {
  width: 300px;
}
.schedule-form-level-code-selector {
  width: 100px;
}
.schedule-form-level-name-selector {
  width: 200px;
}
.strand-level-name-selector {
  width: 200px;
  margin-left: 5px;
}
.schedule-form-target-level-selector {
  min-width: 200px;
}
.schedule-form-strand-type-selector {
  width: 140px;
}
.schedule-form-product-selector {
  min-width: 280px;
}
.schedule-form-virtual-centre-selector {
  min-width: 280px;
}
.schedule-form-day-selector {
  width: 200px;
}
.schedule-form-module-selector {
  width: 150px;
}
.schedule-form-frequency-div {
  margin: 25px 15px 25px 15px;
}
.schedule-form-radio-div {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.schedule-form-radio {
  width: 25px;
}
.schedule-form-radio-label {
  margin-top: 4px;
}
.schedule-form-classroom-name {
  width: 200px;
}
.selectors-div {
  display: flex;
  align-items: center;
  gap: 3px;
}
.schedule-form-duration {
  width: 35px;
  border-radius: 5px;
}
.schedule-form-capacity {
  width: 35px;
}
.schedule-form-date {
  width: 90px;
}
.schedule-form-credit {
  width: 40px;
}

.content-selectors-div {
  display: flex;
  gap: 3px;
}
.schedule-form-password {
  width: 200px;
}
.schedule-form-schedule {
  width: 300px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.schedule-form-schedule-selector {
  width: 320px;
}

.centre-selector-div {
  display: flex;
  justify-content: left;
  text-align: left;
}
.group-type-div {
  display: flex;
}
