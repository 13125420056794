/* Styling for login form only */

.login-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 90%;
}

.login-form {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px 70px 30px 70px;
  width: fit-content;
  min-width: 200px;
  position: relative;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.login-input {
  width: 200px;
}

.login-button-div {
  text-align: left;
}

.login-error-div {
  padding-top: 10px;
  color: red;
}

.login-show-password-checkbox {
  width: 15px;
  margin-right: 7px;
}

.form-group {
  margin-bottom: 10px;
}

.otp-div {
  width: 250px;
}

.otp-input-div {
  padding: 15px 0px 15px 0px;
}

.otp-message-div {
  color: red;
  padding: 15px 0px 15px 0px;
}

/* News modal */

.news-content {
  margin: 30px 30px;
  text-align: left;
}

.news-div {
  max-width: 600px;
}

.news-div li {
  padding-bottom: 5px;
}

.news-list {
  /* list-style: none; */
  padding-left: 15px;
}

.news-info-div {
  display: flex;
}

.news-info-left-div {
  flex: 50%;
}
.news-info-right-div {
  text-align: right;
  flex: 50%;
}

.news-button-div {
  padding: 15px 0px 5px 0px;
}

/* Zoom */

.inactive-account {
  background-color: rgb(240, 220, 220);
}

.zoom-info-div {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.zoom-info-left-div {
  flex: 50%;
}

.zoom-info-right-div {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  text-align: right;
  flex: 50%;
}

.zoom-search-input {
  width: 250px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.hide-basic {
  display: none;
}

.zoom-user-modal {
  min-width: 1000px;
  text-align: left;
}

.close-button-div {
  padding-top: 15px;
  text-align: center;
}

.highlighted-row {
  background-color: rgb(228, 248, 228);
}

.zoom-header-cell {
  background-color: #d8e6f78f;
  border: 1px white solid;
  width: 60px;
}

.zoom-info-cell {
  width: 120px;
}

.column-filter {
  cursor: pointer;
}

.column-filter:hover {
  text-decoration: underline;
}

.sort-icon {
  color: white;
  margin-left: 5px;
}

.selected-user {
  /* border: 1.5px solid #2b2577; */
  /* background-color: #f0f0f0; */
}

.zoom-meetings {
  margin-top: 25px;
}

.meetings-tab-div {
  display: flex;
  align-items: center;
}

.meetings-tab {
  border: 1px solid #ccc;
  text-align: center;
  padding: 10px 70px;
  cursor: pointer;
}

.left-tab {
  border-radius: 10px 0px 0px 0px;
}

.right-tab {
  border-radius: 0px 10px 0px 0px;
}

button:disabled {
  color: #ccc;
  border: 1px solid #ccc;
  cursor: not-allowed;
}

/* .zoom-account-div {

} */

/* .zoom-account-left-div {
  flex: 85%;
} */
.zoom-account-right-div {
  text-align: center;
}

/* .button-disabled {
  color: #ccc;
  border: 1px solid #ccc;
  cursor: not-allowed;
} */
